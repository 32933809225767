import {CREATE_MESSAGE, CLEAR_MESSAGE} from "../ActionType/MessageActionTypes";

const initialState = {
    message: null,
    type: null
}


const messageReducer = (state = initialState, action) => {
    switch(action.type) {
        case CREATE_MESSAGE: 
        return {
            ...state,
            message: action.payload.message,
            type: action.payload.type
        };
        case CLEAR_MESSAGE: 
        return {
            ...state,
            message: null,
            type: null
        };
        default: 
        return state;
    }
}

export default messageReducer;